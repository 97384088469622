export const currencyList = [
   {
      code: 'AED',
      name: 'UAE',
      flag: '🇦🇪',
   },
   {
      code: 'USD',
      name: 'International',
      flag: '🇺🇸',
   },
   {
      code: 'SAR',
      name: 'KSA',
      flag: '🇸🇦',
   },
   {
      code: 'KWD',
      name: 'Kuwait',
      flag: '🇰🇼',
   },
   {
      code: 'JOD',
      name: 'Jordan',
      flag: '🇯🇴',
   },
   {
      code: 'QAR',
      name: 'Qatar',
      flag: '🇶🇦',
   },
   {
      code: 'EUR',
      name: 'EU',
      flag: '🇪🇺',
   },
   {
      code: 'GBP',
      name: 'UK',
      flag: '🇬🇧',
   },
];
